import 'react-app-polyfill/stable';
import 'core-js';
import React from 'react';
import { Global, css } from '@emotion/react';
import { createRoot } from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import store from './store';
import { GoogleOAuthProvider } from '@react-oauth/google';

//const secret = process.env.REACT_APP_GOOGLE_SECRET
const secret =
  '427917579937-3do09g92pvpcd0cs4dt3houjgg7iaar1.apps.googleusercontent.com';

const GlobalStyles = () => (
  <Global
    styles={css`
      /* Apply global styles */
      body {
        font-family: 'Open Sans', sans-serif;
      }
    `}
  />
);

createRoot(document.getElementById('root')).render(
  <Provider store={store}>
    <GoogleOAuthProvider clientId={secret}>
      <GlobalStyles />
      <App />
    </GoogleOAuthProvider>
  </Provider>
);

reportWebVitals();
